.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background-container {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.background-container::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -10;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(34, 112, 195, 0.47524947478991597) 0%,
    rgba(110, 255, 27, 0) 100%
  );
}

.background-container::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -9;
  height: 100%;
  width: 100%;
  background-size: 20px 20px;
  mask-image: radial-gradient(
    ellipse 80% 50% at 50% 0%,
    #000 70%,
    transparent 110%
  );
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
